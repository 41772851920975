<template>
<div>
  <div v-if="ready">
    <div class="mb-2">Your email:</div>
    <b-form-input class="mb-4" v-model="email"></b-form-input>
    <div class="mb-2">Message:</div>
    <b-form-textarea class="mb-4" v-model="message" rows="10"></b-form-textarea>
    <b-button class="mb-4" :disabled="!valide" variant="primary" @click="send">Send</b-button>
  </div>
  <div v-if="sending">
    <div class="mb-4">We are sending your message</div>
    <div class="mb-4"><b-spinner></b-spinner></div>
  </div>
  <div v-if="sent">
    <div class="mb-4">Thank you for your feedback. Your message was sent to lucas.ocelewicz@kpmg.co.uk</div>
  </div>
  <div class="mb-4">If you prefer, you can write to us directly by sending an email to <a href="mailto:lucas.ocelewicz@kpmg.co.uk?subject=CPRD%20Feedback">Lucas Ocelewicz (lucas.ocelewicz@kpmg.co.uk)</a></div>
</div>
</template>

<script>
export default {
  name: 'ContactForm',
  props: ['subject'],
  components: {
  },
  computed: {
    valide: function () {
      const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      const isEmail = regex.test(this.email)
      return isEmail && this.message.length > 5
    }
  },
  created: function () {
  },
  data () {
    return {
      email: '',
      message: '',
      ready: true,
      sending: false,
      sent: false
    }
  },
  methods: {
    send: async function () {
      this.sending = true
      this.ready = false
      const payload = {
        email: this.email,
        message: this.message,
        subject: this.subject ? this.subject : 'CPRD Feedback'
      }
      await this.$Amplify.API.post('cosmos', '/contact-form', { body: payload })
      this.sending = false
      this.message = ''
      this.sent = true
    }
  }
}
</script>

<style>
</style>
