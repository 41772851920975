<template>
<div>
  <b-container fluid style="background-image: url('/img/ai-background.jpg'); background-repeat: no-repeat; background-size: cover; height: 478px; margin: 0px;">
    <b-row>
      <b-col style="color: white;">
        <b-container>
          <b-row>
            <b-col>
              <h1 class="kstyle" style="font-size: 42px; font-weight: 500; margin-top: 200px;">Welcome to Market Abuse Insights</h1>
              <p style="font-size: 18px;">Curated by a network of specialists,
                latest insights and reference enforcement cases on market abuse risk management.</p>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
  <b-container class="mt-4">
    <b-row class="link-cards">
      <b-col lg="12">
        <h2 class="mb-4" style="font-size: 20px; font-weight: bold;">Get Started</h2>
      </b-col>
      <b-col class="mb-5" lg="3" md="6">
        <router-link :to="{ name: 'MarCases' }" class="link-card">
          <div class="p-0 m-0" style="background-color: #1E49E2; color: white; height: 220px;">
            <div class="p-0 m-0" style="text-align: center; height: 130px;"><b-icon-folder class="home-icon"></b-icon-folder></div>
            <div class="link-card-link p-3 m-0" style="text-align: center;">Browse cases</div>
          </div>
        </router-link>
      </b-col>
      <b-col class="mb-5" lg="3" md="6">
        <router-link :to="{ name: 'MarRisks', params: {id: 154 }}" class="link-card">
          <div class="p-0 m-0" style="background-color: #1E49E2; color: white; height: 220px;">
            <div class="p-0 m-0" style="text-align: center; height: 130px;"><b-icon-grid-3x3 class="home-icon"></b-icon-grid-3x3></div>
            <div class="p-3 m-0" style="text-align: center;">Explore risk taxonomy</div>
          </div>
        </router-link>
      </b-col>
      <b-col class="mb-5" lg="3" md="6">
        <router-link :to="{ name: 'MarNews', params: {id: 154 }}" class="link-card">
          <div class="p-0 m-0" style="background-color: #1E49E2; color: white; height: 220px;">
            <div class="p-0 m-0" style="text-align: center; height: 130px;"><b-icon-newspaper class="home-icon"></b-icon-newspaper></div>
            <div class="p-3 m-0" style="text-align: center;">Access latest news</div>
          </div>
        </router-link>
      </b-col>
      <b-col class="mb-5" lg="3" md="6">
        <router-link :to="{ name: 'MarInsights' }" class="link-card">
          <div class="p-0 m-0" style="background-color: #1E49E2; color: white; height: 220px;">
            <div class="p-0 m-0" style="text-align: center; height: 130px;"><b-icon-binoculars class="home-icon"></b-icon-binoculars></div>
            <div class="p-3 m-0" style="text-align: center;">Explore insights</div>
          </div>
        </router-link>
      </b-col>
    </b-row>
    <b-row v-if="aipost">
      <b-col>
        <h2 class="mb-2" style="font-size: 20px; font-weight: bold;">AI on Market Abuse Insights</h2>
        <p class="mb-3">Summary of recent developments generated by artificial intelligence - may contain mistakes</p>
      </b-col>
    </b-row>
    <b-row class="mb-5" v-if="aipost">
      <b-col>
        <b-container class="shadow p-4 bg-white">
          <h4 class="mb-3" style="font-size: 18px; font-weight: bold;">{{ aipost.name }}</h4>
          <div class="mb-3">{{ aipost.created_at.slice(0,10) }}</div>
          <div>
            <markdown :content="aipost.body" />
          </div>
        </b-container>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mb-5">
        <h2 style="font-size: 20px; font-weight: bold;">About Market Abuse Insights</h2>
        <b-container class="shadow p-4 bg-white">
          <b-row>
            <b-col class="text-center" lg="4" sm="12">
              <div>
                <div style="margin: 0 auto; clip-path: circle(50% at 50% 50%); background-image: url('/img/lucasocelewicz.jpg'); background-position: center; background-size: cover; width: 153px; height: 153px;"></div>
              </div>
              <p class="mb-0"><strong>Lucas Ocelewicz</strong></p>
              <p class="mb-0"><small>Partner</small></p>
              <p class="mb-5"><small>KPMG in the UK</small></p>
              <div>
                <div style="margin: 0 auto; clip-path: circle(50% at 50% 50%); background-image: url('/img/dharamshah.jpg'); background-position: center; background-size: cover; width: 153px; height: 153px;"></div>
              </div>
              <p class="mb-0"><strong>Dharam Shah</strong></p>
              <p class="mb-0"><small>Director</small></p>
              <p><small>KPMG in the UK</small></p>
            </b-col>
            <b-col lg="8" sm="12">
              <div>
                <p>KPMG Market Abuse Insights is your gateway to staying ahead in a rapidly changing regulatory landscape. Designed to support
                   surveillance and compliance teams, our platform captures significant regulatory developments and high-impact enforcement cases,
                   helping you stay informed about the latest shifts in market conduct standards.</p>
                <p>With our curated enforcement cases database,
                   teams can better understand evolving risk profiles and adopt a data-led approach to risk assessment,
                   elevating their ability to identify, assess, and mitigate risks effectively.</p>
                <p>Our ongoing regulatory news updates provide timely insights into upcoming changes,
                  ensuring you remain proactive and well-prepared for new challenges on the regulatory horizon.</p>
                <p>Additionally, KPMG Market Abuse Insights connects you directly to our team of market conduct specialists,
                  offering tailored guidance and support to help your organization strengthen its compliance framework.</p>
                <p>Navigate today’s complexities in market abuse prevention with confidence through KPMG’s trusted expertise and insights.</p>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
  <b-container class="mb-5">
    <b-row v-if="rows.length > 0">
      <b-col>
          <h2 style="font-size: 20px; font-weight: bold;">Our case inventory</h2>
          <p class="mb-3">We track significant market abuse cases and notices. Explore <router-link :to="{ name: 'MarCases' }"><strong>Cases</strong></router-link> to find out more.</p>
          <b-container class="shadow p-4 bg-white">
            <div class="my-5" v-for="chart in barChartsStacked3" :key="chart.key">
              <bar-stacked-series v-if="Array.isArray(chart.data) && chart.data.length > 0" :config="chart"/>
            </div>
          </b-container>
      </b-col>
    </b-row>
  </b-container>
  <b-container>
    <b-row>
      <b-col lg="8" md="12">
        <h2 style="font-size: 20px; font-weight: bold;">KPMG Insights</h2>
        <p>Recent KPMG publications</p>
        <div v-if="!loadingArticles && articles.length > 0">
          <b-container class="shadow p-5" style="background-color: #1E49E2; color: white;">
            <b-row>
              <b-col lg="8" sm="12">
                <div><b-link :to="{ name: 'MarInsight', params: {id: article.id }}" style="color: white;"><strong>{{article.title}}</strong></b-link></div>
                <div class="mb-2"><small>{{moment(article.approved_at).format('YYYY-MM-DD')}} by {{ article.approved_by }}</small></div>
                <div>{{ article.summary }}</div>
              </b-col>
              <b-col class="text-center" lg="4" sm="12">
                <div>
                  <div :style="article.style"></div>
                </div>
              </b-col>
            </b-row>
          </b-container>
          <div v-for="item in articles" :key="'articles'+item.id" class="my-4">
            <b-container class="shadow p-3 bg-white">
              <b-row>
                <b-col>
                  <div><b-link :to="{ name: 'MarInsight', params: {id: item.id }}"><strong>{{item.title}}</strong></b-link></div>
                  <div class="mb-2"><small>{{moment(item.approved_at).format('YYYY-MM-DD')}} by {{ item.approved_by }}</small></div>
                  <div>{{ item.summary }}</div>
                </b-col>
              </b-row>
            </b-container>
          </div>
          <div class="mt-4 mb-2"><b-link :to="{ name: 'MarInsights' }"><strong>More Insights</strong></b-link></div>
        </div>
        <div class="my-3" v-if="loadingArticles">
          <b-spinner label="loading insights..." />
          loading insights...
        </div>
        <h2 class="mt-5" style="font-size: 20px; font-weight: bold;">Contact us</h2>
        <p>Provide feedback, request features or support</p>
        <b-container class="shadow p-3 bg-white">
          <b-row>
            <b-col>
              <contact-form subject="KPMG Market Abuse Insights Feedback" />
            </b-col>
          </b-row>
        </b-container>
      </b-col>
      <b-col lg="4" md="12">
        <h2 style="font-size: 20px; font-weight: bold;">News</h2>
        <p>Recent industry updates</p>
        <div class="shadow py-4 px-3 bg-white" v-if="!loading">
          <div class="my-3" v-for="(item, index) in news" :key="'news-' + index">
            <div>
              <router-link :to="{ name: 'MarNewsItem', params: { id: item.id }}">
                <div><strong>{{item.name}}</strong></div>
                <div><small>{{ item.date.slice(0,10) }} - {{ item.countryName }} - {{ item.publisher }}</small></div>
              </router-link>
            </div>
          </div>
          <div>
            <router-link class="mt-4 mb-2" :to="{ name: 'MarNews', params: {id: 154 }}"><strong>More News</strong></router-link>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import contactForm from '@/components/ContactForm.vue'
import dataGetters from '@/libs/dataGetters'
import Markdown from '@/components/Markdown.vue'
import BarStackedSeries from '@/components/charts/v1/barStackedSeries.vue'
import { BIconBinoculars, BIconFolder, BIconGrid3x3, BIconNewspaper } from 'bootstrap-vue'

function getUsernameFromEmail (email) {
  const match = email.match(/^[^@]+/)
  return match ? match[0].replace(/\./g, '') : null
}

function getRandomKey () {
  return Math.floor(Math.random() * 10000)
}

export default {
  name: 'AiHome',
  components: {
    BarStackedSeries,
    BIconBinoculars,
    BIconFolder,
    BIconGrid3x3,
    BIconNewspaper,
    contactForm,
    Markdown
  },
  computed: {
    barChartsStacked3: function () {
      const results = []
      const charts = [
        {
          dataGetter: 'arrayStacked',
          propertyPrimary: 'risks',
          namePropertyPrimary: 'name',
          propertySecondary: 'assetclasses',
          namePropertySecondary: 'name',
          nameSeries: 'Risks and asset classes',
          header: 'Cases by risks and asset classes',
          headerRecord: 'risks'
        }
      ]
      _.each(charts, chart => {
        const data = dataGetters[chart.dataGetter](this.rows, chart)
        const config = {
          data: data,
          name: chart.nameSeries,
          header: chart.header,
          headerRecord: chart.headerRecord,
          height: '800px',
          width: '100%',
          key: getRandomKey()
        }
        results.push(config)
      })
      return results
    },
    rows: function () {
      return this.$store.state.cases
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  data () {
    return {
      aipost: null,
      article: null,
      articles: [],
      loading: true,
      loadingArticles: true,
      moment: moment,
      news: []
    }
  },
  created: async function () {
    this.$store.commit('setBrand', 'Market Abuse Insights')
    document.title = "KPMG Market Abuse Insights: Home"
    const aiposts = await this.$Amplify.API.post('cosmos', `/aiposts/topics/limit/1/offset/0`, { body: { topics: [1] } })
    if (aiposts.length > 0) {
      this.aipost = aiposts[0]
    }
    this.news = await this.$Amplify.API.post('cosmos', '/news/topics/standardformat/limit/12', { body: { topics: [1] } })
    this.loading = false
    const articles = await await this.$Amplify.API.post('cosmos', `/articles/topics/limit/5`, { body: { topics: [1] } })
    articles.forEach(x => {
      const img = getUsernameFromEmail(x.approved_by)
      x.img = img
      x.style = `margin: 0 auto; clip-path: circle(50% at 50% 50%); background-image: url('/img/${img}.jpg'); background-position: center; background-size: cover; width: 153px; height: 153px;`
    })
    const article = articles.shift()
    this.article = article
    this.articles = articles
    this.loadingArticles = false
  },
  methods: {
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
.carousel-caption {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%; /* Ensure the div takes full height of the slide */
  padding-top: 1rem; /* Optional: add some padding to the top */
}

.carousel-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%; /* Ensure the div takes full height of the slide */
  padding-top: 1rem; /* Optional: add some padding to the top */
}

.home-icon {
  color: white;
  height: 90px;
  width: 90px;
  margin: 20px;
}
.link-card {
  font-size: 16px;
  font-weight: bold;
}

.link-card div:hover {
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
}

.link-cards a:hover {
    text-decoration: none;
}
</style>
